<template>
  <ListPageWithCreateButton
    createRoutName="scoreManager.create"
    :showCreateBtn="checkUserRole('SND')"
    :createBtnName="$t('scoreManager.create')">
    3
    <template slot="listDataContent">
      <data-table
        csv_url="score-manager/export"
        :headers_prop="headers"
        list_url_prop="/score-manager" />
    </template>
  </ListPageWithCreateButton>
</template>

<script>
import DataTable from '@/components/general/datatable/DataTable2.vue'

export default {
  name: 'List',
  components: {DataTable},
  comments: [DataTable],
  data() {
    return {
      loading: false,
      searching: '',
      status: [
        {
          id: 0,
          title: this.$t('status.active'),
          value: 1,
          icon: 'StarIcon',
          count: 0
        },
        {
          id: 1,
          title: this.$t('status.suspended'),
          value: 0,
          icon: 'ClipboardIcon',
          count: 0
        }
      ],

      headers: [
        {
          headerName: this.$t('RoundManager.S'),
          field: 'id',
          checkboxSelection: true,
          width: 200,
          minWidth: 160,
          headerCheckboxSelectionFilteredOnly: true,
          headerCheckboxSelection: true
        },
        {
          headerName: this.$t('scoreManager.schema_made_by'),
          field: 'schema_made_by',
          width: 200,
          minWidth: 160
        },
        {
          headerName: this.$t('scoreManager.usage'),
          field: 'usage',
          minWidth: 160
        },
        {
          headerName: this.$t('basic.date_created'),
          field: 'create_at',
          minWidth: 160
        },
        {
          headerName: this.$t('scoreManager.last_date_modification'),
          field: 'update_at',
          minWidth: 160
        },
        {
          headerName: this.$t('basic.schools'),
          field: 'school',
          minWidth: 160
        },
        {
          headerName: this.$t('basic.divisions'),
          field: 'division',
          minWidth: 160
        },

        {
          headerName: this.$t('basic.status'),
          field: 'status',
          width: 240,
          minWidth: 160,
          cellRendererFramework: 'CellRendererStatus',
          cellRendererParams: {
            status_prop: [
              {
                title: this.$t('status.active'),
                value: 1
              },
              {
                title: this.$t('status.suspended'),
                value: 0
              }
            ]
          }
        }
      ]
    }
  },

  methods: {
    ///////////////////////////////////
    // Hide , show datatble Actions
    ///////////////////////////////////
    toggleDatatbleActionsCol() {
      const actionsCol = {
        headerName: this.$t('subject.Actions'),
        field: 'transactions',
        width: 240,
        minWidth: 160,
        cellRendererFramework: 'CellRendererActions',
        cellRendererParams: {
          actions_prop: [
            {
              title: this.$t('main.Edit'),
              route_name: 'scoreManager.edit',
              parameter_name: 'id',
              icon: 'Edit3Icon',
              navigate_action: true
            },
            {
              title: this.$t('basic.delete'),
              resource: '/score-manager/:id',
              parameter_name: 'id',
              action: 'delete',
              requestType: 'delete'
            },

            {
              title: this.$t('basic.actions'),
              dropDown: true,
              parameter_name: 'id',
              icon: 'MoreVerticalIcon',
              navigate_action: false,
              dropDown_items: [
                {
                  title: this.$t('basic.activate'),
                  icon: 'CheckCircleIcon',
                  resource: '/score-manager/:id',
                  action: 'toggle-status',
                  requestType: 'post',
                  statusTarget: 'active'
                },
                {
                  title: this.$t('basic.suspend'),
                  icon: 'AlertCircleIcon',
                  resource: '/score-manager/:id',
                  action: 'toggle-status',
                  requestType: 'post',
                  statusTarget: 'suspend'
                }
              ]
            }
          ]
        }
      }
      // if SND
      if (!this.checkUserRole('SMD')) this.headers.push(actionsCol)
    }
  },
  created() {
    this.toggleDatatbleActionsCol()
  }
}
</script>

<style scoped></style>
